import React from 'react'
import Link from 'next/link'

class PageNotFound extends React.Component {
  render () {
    return (
      <div style={{ paddingTop: '100px' }}>
        <div className="main-login">
          <div className="main-login-container">
            <div className="error-template">
              <h1 className="text-center">Page Not Found</h1>
              <div>
                <p>
                  We couldn{"'"}t find what you came here for but you can bet we are
                  going to investigate.
                </p>
                <p>
                  In the meantime, maybe you{"'"}d like to browse the <Link href={'/marketplace'}>marketplace</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PageNotFound
