import React from 'react'
import PageNotFound from '../components/default/PageNotFound'

const Four04 = () => {
  return (
    <PageNotFound />
  )
}

export default Four04
